



















import Component, { mixins } from "vue-class-component"
import { Watch, Prop } from "vue-property-decorator"
import { deserialize } from "typescript-json-serializer"
import { Swiper, SwiperSlide } from "vue-awesome-swiper"
import { SwiperOptions } from "swiper"
import _ from "lodash"

import HomeApi from "@/api/home/HomeApi"
import BreakpointMixin from "@/types/BreakpointMixin"
import HomeProductServices from "@/types/Home/HomeProductServices"
import HomeProductServiceHeader from "@/types/Home/HomeProductServiceHeader"

import CardProductServices from "@/components/Home/CardProductServices.vue"
@Component({
  name: "HomeProductServices",
  components: {
    Swiper,
    SwiperSlide,
    CardProductServices
  }
})
export default class ProductServices extends mixins(BreakpointMixin) {
  @Prop({ default: "550px" }) height!: string

  /** Variables */
  private products: HomeProductServices[] = []

  private productHeader: HomeProductServiceHeader = {
    id: 0,
    title: "",
    sub_title: "",
    content: "",
    link: "",
    file: {}
  }

  /** Swiper Config */
  // // eslint-disable-next-line class-methods-use-this
  private swiperOptions: SwiperOptions = {
    slidesPerGroup: 1,
    slidesPerView: 3,
    loop: true,
    loopFillGroupWithBlank: false,
    autoplay: true,
    preloadImages: true,
    updateOnImagesReady: false,
    updateOnWindowResize: true,
    spaceBetween: 24,
    pagination: {
      el: ".swiper-pagination",
      clickable: true,
      dynamicBullets: true,
      dynamicMainBullets: 1
    },
    // centeredSlides: true,
    breakpoints: {
      991: {
        slidesPerView: 3
      },
      100: {
        slidesPerView: 1,
        // slidesPerGroup: 3,
        centeredSlides: true,
        spaceBetween: 12
      }
    }
  }

  /** Watcher */
  @Watch("$route", {
    immediate: true,
    deep: true
  })
  onUrlChange() {
    this.fetchData()
  }
  /** onMounted */
  private mounted() {
    this.fetchData()
  }

  /** Functions */
  private fetchData() {
    HomeApi.getProductServices().then(({ data }) => {
      this.products = _.map(data, (d) => deserialize<HomeProductServices>(d, HomeProductServices))
    })

    HomeApi.getProductServiceHeader().then(({ data }) => {
      const [productHeader] = _.map(data, (d) => deserialize<HomeProductServiceHeader>(d, HomeProductServiceHeader))
      this.productHeader = productHeader
    })
  }

  private seeMore() {
    const lang = this.$i18n.locale
    this.$router.push({ name: "NewsListPage", query: { top: "true", lang } })
  }

  private viewNews(item: HomeProductServices) {
    const lang = this.$i18n.locale
    this.$router.push({
      name: "NewsDetailPage",
      params: { id: `${item.id}` },
      query: { lang }
    })
  }

  private generateRoute(item: HomeProductServices) {
    return {
      name: "NewsDetailPage",
      params: { id: `${item.id}` },
      query: { lang: this.$i18n.locale }
    }
  }

  /** Getter */
  private get heroWidth() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) {
      return 623
    }
    return 400
  }

  private get heroHeight() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) return 414
    if (bp.lgOnly) return 275
    return 200
  }

  private get imageWidth() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) {
      return 243
    }
    return 162
  }

  private get imageHeight() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) {
      return 122
    }
    return 81
  }
}
