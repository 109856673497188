import { JsonProperty, Serializable } from "typescript-json-serializer"
import DiamondFile from "@/types/DiamondFile"

@Serializable()
export default class HomeProducts {
  @JsonProperty("product_service_header_id") id!: number

  @JsonProperty() title!: string

  @JsonProperty() sub_title!: string

  @JsonProperty() content!: string

  @JsonProperty() link!: string

  @JsonProperty({ type: DiamondFile }) file: DiamondFile = new DiamondFile()
}
