













































import Component, { mixins } from "vue-class-component"
import { Watch } from "vue-property-decorator"
import { deserialize } from "typescript-json-serializer"
import { SwiperOptions } from "swiper"
import { Swiper, SwiperSlide } from "vue-awesome-swiper"

import HomeApi from "@/api/home/HomeApi"
import HomeBanner from "@/types/Home/HomeBanner"
import BreakpointMixin from "@/types/BreakpointMixin"
import HomeProductHighlight from "@/types/Home/HomeProductHighlight"

import SkewedCarousel from "@/views/Carousel/SkewedCarousel.vue"
import Announcement from "@/components/Home/Announcement.vue"
import ProductHighlight from "@/components/Home/ProductHighlight.vue"
import Activities from "@/components/Home/Activities.vue"
import ProductServices from "@/components/Home/ProductServices.vue"
import Articles from "@/components/Home/Articles.vue"
// @ts-ignore
import AOS from "aos"
import _ from "lodash"
import { mapState } from "vuex"

@Component({
  name: "Home",
  components: {
    SkewedCarousel,
    Swiper,
    SwiperSlide,
    Activities,
    ProductHighlight,
    ProductServices,
    Announcement,
    Articles
  },
  computed: { ...mapState(["menus"]) }
})
export default class HomePage extends mixins(BreakpointMixin) {
  private banners: HomeBanner[] = []

  private highlight = new HomeProductHighlight()

  private swiperOptions: SwiperOptions = {
    effect: "fade",
    speed: 1000,
    loop: true,
    slidesPerView: 1,
    autoplay: {
      delay: 3000
    },
    pagination: {
      el: ".swiper-pagination",
      clickable: true
    },
    updateOnWindowResize: true
  }

  private get highlightBackHeight() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) return 368
    if (bp.mdAndUp) return 245
    return 183
  }

  private get highlightBackWidth() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) return 507
    if (bp.mdAndUp) return 337
    return 251
  }

  private get highlightHeight() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) return 252
    if (bp.mdAndUp) return 167
    return 125
  }

  private get highlightWidth() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) return 298
    if (bp.mdAndUp) return 198
    return 148
  }

  @Watch("$route", {
    immediate: true,
    deep: true
  })
  onUrlChange() {
    // Router from vue-router
    this.fetchData()
  }

  private fetchData() {
    HomeApi.getBanner().then(({ data }) => {
      this.banners = _.map(data, (d) => deserialize<HomeBanner>(d, HomeBanner))
    })
    HomeApi.getHighlight()
      .then(({ data }) => {
        this.highlight = deserialize<HomeProductHighlight>(data, HomeProductHighlight)
      })
      .then(() => {
        AOS.refresh()
      })
  }

  private mounted() {
    window.scrollTo({ top: 0 })
    this.fetchData()
  }

  private visitHighlightLink() {
    const langQuery = `lang=${localStorage.getItem("lang") || "th"}`
    window.location.href = `${this.highlight.link}&?${langQuery}`
  }

  get swiperHeight() {
    const width = window.screen.width
    return width > 1280 ? "550px" : width < 991 ? "370px" : "337px"
  }
}
