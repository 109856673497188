import { JsonProperty, Serializable } from "typescript-json-serializer"
import DiamondFile from "@/types/DiamondFile"

@Serializable()
export default class HomeCarousel {
  @JsonProperty("home_carousel_id") id!: number

  @JsonProperty({ type: DiamondFile }) file: DiamondFile = new DiamondFile()

  @JsonProperty() link?: string
}
