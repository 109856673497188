import { JsonProperty, Serializable } from "typescript-json-serializer"
import DiamondFile from "@/types/DiamondFile"

@Serializable()
export default class HomeArticles {
  @JsonProperty("home_article_id") id!: number

  @JsonProperty("blog_id") blogId!: number

  @JsonProperty() title!: string

  @JsonProperty() content!: string

  @JsonProperty({ type: DiamondFile }) file: DiamondFile = new DiamondFile()
}
