import DiamondBaseAPI from "@/api/Api"
import ResponseBase from "@/types/ResponseBase"
import HomeBanner from "@/types/Home/HomeBanner"
import HomeProductHighlightList from "@/types/Home/HomeProductHighlightList"
import HomeCarousel from "@/types/Home/HomeCarousel"
import HomeActivity from "@/types/Home/HomeActivity"
import HomeProductHighlight from "@/types/Home/HomeProductHighlight"
import HomeMarketPlace from "@/types/Home/HomeMarketPlace"
import HomeProductServices from "@/types/Home/HomeProductServices"

export default class HomeApi extends DiamondBaseAPI {
  static getBanner(sort = "YES"): Promise<ResponseBase<HomeBanner[]>> {
    return this.api.get(`home-banner${sort === "YES" ? "?sort=YES" : ""}`).then(({ data }) => data)
  }

  static getHighlight(): Promise<ResponseBase<HomeProductHighlight>> {
    return this.api.get("home-product-highlight").then(({ data }) => data)
  }

  static listHighlight(): Promise<ResponseBase<HomeProductHighlightList[]>> {
    return this.api.get("home-product-highlight-list").then(({ data }) => data)
  }

  static listCarousel(): Promise<ResponseBase<HomeCarousel>> {
    return this.api.get("home-carousel").then(({ data }) => data)
  }

  static listActivities(): Promise<ResponseBase<HomeActivity[]>> {
    return this.api.get("home-activity").then(({ data }) => data)
  }

  static getMarketPlace(): Promise<ResponseBase<HomeMarketPlace>> {
    return this.api.get("home-market-place").then(({ data }) => data)
  }

  static getProductServices(): Promise<ResponseBase<HomeProductServices>> {
    return this.api.get("product-services").then(({ data }) => data)
  }

  static getProductServiceHeader(): Promise<ResponseBase<HomeProductServices>> {
    return this.api.get("product-service-header").then(({ data }) => data)
  }

  static getArticles(): Promise<ResponseBase<HomeProductServices>> {
    return this.api.get("home-articles").then(({ data }) => data)
  }
}
