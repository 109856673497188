






















import Component, { mixins } from "vue-class-component"
import { Prop } from "vue-property-decorator"
import _ from "lodash"

/** Types */
import BreakpointMixin from "@/types/BreakpointMixin"
import HomeArticles from "@/types/Home/HomeArticles"

@Component({
  name: "CardProductServices"
})
export default class CardProductServices extends mixins(BreakpointMixin) {
  @Prop({
    default: {
      title: "กระเบื้อง CT เพชร",
      blog_id: 0
    }
  })
  article!: HomeArticles

  get imageHeight() {
    const width = window.screen.width
    return width > 1440 ? "550px" : width > 1280 ? "440px" : width < 991 ? "384px" : "370px"
  }

  get storageLang() {
    return localStorage.getItem("lang") || "th"
  }
}
