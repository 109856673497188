




























































import Component, { mixins } from "vue-class-component"
// @ts-ignore
import NextBtn from "@/assets/buttons/next.png"
// @ts-ignore
import PrevBtn from "@/assets/buttons/prev.png"
import { SwiperOptions } from "swiper"
import { Swiper, SwiperSlide } from "vue-awesome-swiper"
import HomeApi from "@/api/home/HomeApi"
import _ from "lodash"
import { deserialize } from "typescript-json-serializer"
import HomeProductHighlightList from "@/types/Home/HomeProductHighlightList"
import BreakpointMixin from "@/types/BreakpointMixin"
import { Watch } from "vue-property-decorator"

@Component({
  name: "ProductHighlight",
  components: {
    Swiper,
    SwiperSlide
  }
})
export default class ProductHighlight extends mixins(BreakpointMixin) {
  private nextBtn = NextBtn

  private prevBtn = PrevBtn

  private carousel: HomeProductHighlightList[] = []

  private get cardHeight() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) {
      return 400
    }
    if (bp.mdAndUp) {
      return 400
    }
    return 400
  }

  private get swiperHeight() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) {
      return "500px"
    }
    if (bp.mdAndUp) {
      return "420px"
    }
    return "520px"
  }

  @Watch("$route", { immediate: true, deep: true })
  onUrlChange() {
    // Router from vue-router
    this.fetchData()
  }

  private fetchData() {
    HomeApi.listHighlight().then(({ data }) => {
      this.carousel = _.map(data, (d) => deserialize<HomeProductHighlightList>(d, HomeProductHighlightList))
    })
  }

  private mounted() {
    this.fetchData()
  }

  private get imageSize() {
    const bp = this.$vuetify.breakpoint
    if (bp.xlOnly) return 229
    if (bp.mdAndUp) return 180
    return 229
  }

  private get swiperOptions(): SwiperOptions {
    return {
      // slidesPerView: 1,
      centeredSlides: true,
      autoplay: true,
      preloadImages: true,
      updateOnImagesReady: true,
      updateOnWindowResize: true,
      spaceBetween: 24,
      on: {
        // @ts-ignore
        click: (e: PointerEvent) => {
          // eslint-disable-next-line
          // @ts-ignore
          const [productCategoryId, productGroupId] = e.path[0].alt.split("-")
          this.$router.push({
            name: "ProductItemDetail",
            params: {
              id: `${productGroupId}`,
              detailId: productCategoryId
            }
          })
        }
      },
      // preventClicks: false,
      // slidesPerGroup: 1,
      loop: true,
      // loopFillGroupWithBlank: false,
      pagination: {
        el: ".swiper-pagination",
        clickable: true
      },
      navigation: {
        nextEl: "#prod-highlight-next",
        prevEl: "#prod-highlight-prev"
      },
      breakpoints: {
        1280: {
          slidesPerView: 4,
          centeredSlides: false
        },
        960: {
          slidesPerView: 3,
          centeredSlides: false
        },
        650: {
          // slidesPerView: 2,
          centeredSlides: true
        }
      }
    }
  }
}
