import { JsonProperty, Serializable } from "typescript-json-serializer"
import DiamondFile from "@/types/DiamondFile"
import moment from "moment"
import i18n from "@/i18n"

function transformDate(st: string): string {
  moment.locale(i18n.locale.toUpperCase())
  return moment(st).format("LL")
}

@Serializable()
export default class HomeActivity {
  @JsonProperty("news_id") id!: number

  @JsonProperty() title!: string

  @JsonProperty() news!: string

  @JsonProperty({ name: "created_date", beforeDeserialize: transformDate }) date!: string

  @JsonProperty({ type: DiamondFile }) file: DiamondFile = new DiamondFile()
}
