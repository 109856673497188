import { JsonProperty } from "typescript-json-serializer"
import DiamondFile from "@/types/DiamondFile"

export default class HomeProductHighlight {
  @JsonProperty("home_product_highlight_id") id!: number

  @JsonProperty() title!: string

  @JsonProperty() link!: string

  @JsonProperty() content!: string

  @JsonProperty({ type: DiamondFile }) file: DiamondFile = new DiamondFile()

  @JsonProperty({ name: "file_back", type: DiamondFile }) fileBack: DiamondFile = new DiamondFile()
}
